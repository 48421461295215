import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
// Ionic
import { Storage } from '@ionic/storage'
import { AlertController } from '@ionic/angular'
// RXJS
import { catchError, mergeMap, switchMap } from 'rxjs/operators'
import { from, NEVER, Observable, of } from 'rxjs'
// Env variables
import { environment } from '../../environments/environment'
import { Router } from '@angular/router'
// Services
import { HelpersService } from './helpers.service'

@Injectable({
  providedIn: 'root'
})
export class ApiServiceService {
  baseUrl: string = environment.baseUrl

  constructor (
    private storage: Storage,
    private http: HttpClient,
    private router: Router,
    private helpers: HelpersService,
  ) { }

  // Login user
  login(userAuth): Promise<any> {
    return this.http.post(this.baseUrl + 'login', userAuth).pipe(
      catchError((err) => this.helpers.netWorkError(err))
    ).toPromise()
  }

  logOut(user) {
    this.storage.remove(user).then((res: any) => {
      this.router.navigateByUrl('/login', { replaceUrl: true })
    })
  }

  getAllSelects() {
    return this.http.get(this.baseUrl + 'getAllSelects').pipe(
      mergeMap((res) => this.helpers.checkIfLoggedOut(res)),
      catchError((err) => this.helpers.netWorkError(err))
    ).toPromise()
  }
}
