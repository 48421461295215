import { Injectable } from '@angular/core'
import { AlertController, LoadingController, ToastController } from '@ionic/angular'
import { NotificationsService, NotificationType } from 'angular2-notifications'
import { BehaviorSubject } from 'rxjs'
import { Storage } from '@ionic/storage'

@Injectable({
  providedIn: 'root'
})
export class IonicApiService {
  public loading: HTMLIonLoadingElement[] = []
  public loadingObs$: BehaviorSubject<HTMLIonLoadingElement[]> = new BehaviorSubject([])

  constructor (
    public toastController: ToastController,
    public loadingController: LoadingController,
    public alertController: AlertController,
    private notifications: NotificationsService,
    public storage: Storage,
  ) { }

  async presentLoader(msg): Promise<HTMLIonLoadingElement> {
    let loadingPromise = await this.loadingController.create({
      cssClass: 'my-custom-class',
      message: msg,
      spinner: "crescent",
      duration: 5000
    })
    await loadingPromise.present()
    this.loadingObs$.next(this.loading)
    this.loading.push(loadingPromise)
    return loadingPromise
  }

  saveAlert(type, title, msg) {
    console.log('saveAlert', { type, title, msg })
    this.storage.set('alert', { type, title, msg })
  }

  presentAlert(type, title, msg): void {
    this[type + 'Alert'](title, msg)
  }

  // Normal alert
  normalAlert(title, msg): void {
    this.notifications.create(
      title, msg,
      NotificationType.Bare,
      { theClass: 'outline primary', timeOut: 5000, showProgressBar: false, clickToClose: false }
    )
  }
  // Succcess alert
  successAlert(title, msg): void {
    this.notifications.create(
      title, msg,
      NotificationType.Success,
      { theClass: 'outline', timeOut: 5000, showProgressBar: false, clickToClose: true }
    )
  }
  // Error alert
  errorAlert(title, msg): void {
    this.notifications.create(
      title, msg,
      NotificationType.Error,
      { theClass: 'outline', timeOut: 10000, showProgressBar: false, clickToClose: true }
    )
  }
}
