import { environment } from 'src/environments/environment';
import { UserRole } from '../shared/auth.roles';
const adminRoot = environment.adminRoot;

export interface IMenuItem {
  id?: string;
  icon?: string;
  label: string;
  to: string;
  newWindow?: boolean;
  subs?: IMenuItem[];
  roles?: UserRole[];
}

// list of all the menu items
const data: IMenuItem[] = [
    {
      icon: 'iconsminds-engineering',
      label: 'menu.workers',
      to: `${adminRoot}/workers`,
    },
    {
      icon: 'iconsminds-management',
      label: 'menu.offers',
      to: `${adminRoot}/offers`,
    },
    {
      icon: 'iconsminds-conference',
      label: 'menu.clients',
      to: `${adminRoot}/clients`,
    },
    {
      icon: 'iconsminds-mens',
      label: 'menu.admins',
      to: `${adminRoot}/admins`,
    },
    {
      icon: 'iconsminds-pen',
      label: 'menu.workers-applications',
      to: `${adminRoot}/workers-applications`,
    }
  ];

export default data;

