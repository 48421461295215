<app-list-page-header
 [linkUrl]="'../add-admin'"
 [showOrderBy]="false" 
 [showDisplayMode]="false" 
  (searchKeyUp)="updateFilter($event)" 
 [itemsPerPage]="itemsPerPage" 
 [showItemsPerPage]="false">
</app-list-page-header>

<div class="row">
  <div class="col-12 mb-4 data-table-rows data-table-responsive">
    <ngx-datatable #myTable class="expandable" [columnMode]="ColumnMode.force" [rowHeight]="50" [rows]="rows"
     [footerHeight]="100" [limit]="itemsPerPage" [headerHeight]="20" (page)="onPage($event)">
      <ngx-datatable-row-detail [rowHeight]="95" (toggle)="onDetailToggle($event)" cellClass="datatable-row-detail">
        <ng-template let-column="column" let-row="row" let-expanded="expanded" ngx-datatable-row-detail-template>
          <div class="detail-content">
            <div class="d-flex flex-row">
              <div>
                <div class="text-muted mr-3">
                  Email
                </div>
                <div class="text-muted mr-3">
                  Nom
                </div>
                <div class="text-muted mr-3">
                  Prenom
                </div>
                <div class="text-muted mr-3">
                  Tel
                </div>
              </div>
              <div>
                <div>
                  {{ row.email }}
                </div>
                <div>
                  {{ row.nom }}
                </div>
                <div>
                  {{ row.prenom }}
                </div>
                <div>
                  {{ row.tel }}
                </div>
              </div>
            </div>
          </div>
        </ng-template>
      </ngx-datatable-row-detail>

      <ngx-datatable-column [width]="10" [resizeable]="false" [draggable]="false" [sortable]="false"
       [canAutoResize]="false" class="expand-row-button" headerClass="expand-row-button">
        <ng-template let-row="row" let-expanded="expanded" ngx-datatable-cell-template>
          <a [class.datatable-icon-right]="!expanded" [class.datatable-icon-down]="expanded" title="Expand/Collapse Row"
            (click)="toggleExpandRow(row)" class="d-block d-xl-none c-pointer">
            <i class="glyph-icon simple-icon-arrow-down"></i>
          </a>
        </ng-template>
      </ngx-datatable-column>

      <ngx-datatable-column name="Email" [flexGrow]="1.2" [minWidth]="200" [draggable]="false" [sortable]="false" [resizeable]="false">
        <ng-template let-value="value" ngx-datatable-cell-template>
          <a class="c-pointer">{{ value }}</a>
        </ng-template>
      </ngx-datatable-column>

      <ngx-datatable-column name="Tel" [flexGrow]="0.6" headerClass="d-none d-xl-block" [draggable]="false" [sortable]="false" [resizeable]="false">
        <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
          <span class="d-none d-xl-block">{{ value }}</span>
        </ng-template>
      </ngx-datatable-column>

      <ngx-datatable-column name="Nom" [flexGrow]="0.6" headerClass="d-none d-xl-block" [draggable]="false" [sortable]="false" [resizeable]="false">
        <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
          <span class="d-none d-xl-block">{{ value }}</span>
        </ng-template>
      </ngx-datatable-column>

      <ngx-datatable-column name="Prenom" [flexGrow]="1" headerClass="d-none d-xl-block" [draggable]="false" [sortable]="false" [resizeable]="false">
        <ng-template let-value="value" ngx-datatable-cell-template>
          <span class="d-none d-xl-block">{{ value }}</span>
        </ng-template>
      </ngx-datatable-column>

      <ngx-datatable-column name="Statut" [flexGrow]="1" headerClass="d-none d-xl-block" [draggable]="false" [sortable]="false" [resizeable]="false">
        <ng-template let-value="value" ngx-datatable-cell-template>
          <span class="badge badge-pill badge-danger mb-1" *ngIf="value === 0">Désactivé</span>
          <span class="badge badge-pill badge-success mb-1" *ngIf="value === 1">Activé</span>
        </ng-template>
      </ngx-datatable-column>

      <ngx-datatable-column name="Action" [flexGrow]="1" headerClass="d-none d-xl-block" [draggable]="false" [sortable]="false" [resizeable]="false">
        <ng-template let-value="value" ngx-datatable-cell-template>
          <span class="d-none d-xl-block ngx-action-icons">
            <a tooltip="Modifier" placement="top" class="btn btn-sm btn-outline-primary mr-2">
              <i class="simple-icon-pencil" routerLink="../update-admin/{{value}}"></i>
            </a>
            <a *ngIf="getAdminAccountStatut(value) === 1" tooltip="Désactiver" placement="top" class="btn btn-sm btn-outline-danger mr-2">
              <i class="simple-icon-close" (click)="toggleAdminAccountStatut(value)"></i>
            </a>
            <a *ngIf="getAdminAccountStatut(value) === 0" tooltip="Activer" placement="top" class="btn btn-sm btn-outline-success mr-2">
              <i class="simple-icon-check" (click)="toggleAdminAccountStatut(value)"></i>
            </a>
          </span>
        </ng-template>
      </ngx-datatable-column>

      <ngx-datatable-footer *ngIf="true">
        <ng-template ngx-datatable-footer-template let-rowCount="rowCount" let-pageSize="pageSize"
          let-selectedCount="selectedCount" let-curPage="curPage" let-offset="offset" let-limit="limit">
          <div class="w-100 d-flex justify-content-center">
            <pagination [totalItems]="rowCount" [itemsPerPage]="pageSize" [maxSize]="5" (pageChanged)="table.onFooterPage($event)"
           [customNextTemplate]="nextTemplate" [customPreviousTemplate]="prevTemplate">
            </pagination>
          </div>
        </ng-template>
      </ngx-datatable-footer>

    </ngx-datatable>
  </div>
</div>

<ng-template #nextTemplate let-disabled="disabled" let-currentPage="currentPage">
  <i class='simple-icon-arrow-right'></i>
</ng-template>
<ng-template #prevTemplate let-disabled="disabled" let-currentPage="currentPage">
  <i class='simple-icon-arrow-left'></i>
</ng-template>


