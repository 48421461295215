import { BrowserModule } from '@angular/platform-browser'
import { NgModule } from '@angular/core'
import { AppRoutingModule } from './app.routing'
import { AppComponent } from './app.component'
import { ViewsModule } from './views/views.module'
import { TranslateModule } from '@ngx-translate/core'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { HttpClientModule } from '@angular/common/http'
import { LayoutContainersModule } from './containers/layout/layout.containers.module'
import { SimpleNotificationsModule } from 'angular2-notifications'
import { ScrollbarHelper } from '@swimlane/ngx-datatable'
import { ColumnChangesService } from '@swimlane/ngx-datatable'
import { DimensionsHelper } from '@swimlane/ngx-datatable'
import { PaginationConfig } from 'ngx-bootstrap/pagination'

// ionic
import { IonicModule } from '@ionic/angular'
import { IonicStorageModule } from '@ionic/storage'

// NGX Bootstrap
import { ModalModule } from 'ngx-bootstrap/modal'

@NgModule({
  imports: [
    BrowserModule,
    ViewsModule,
    AppRoutingModule,
    LayoutContainersModule,
    BrowserAnimationsModule,
    TranslateModule.forRoot(),
    HttpClientModule,
    IonicModule.forRoot(),
    IonicStorageModule.forRoot(),
    SimpleNotificationsModule.forRoot(),
    ModalModule.forRoot(),
  ],
  declarations: [
    AppComponent
  ],
  providers: [
    ScrollbarHelper,
    ColumnChangesService,
    DimensionsHelper,
    PaginationConfig,
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
