import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { Router } from '@angular/router'
// RXJS
import { catchError, mergeMap, switchMap } from 'rxjs/operators'
import { from, NEVER, Observable, of } from 'rxjs'
// Ionic
import { Storage } from '@ionic/storage'
// Services
import { IonicApiService } from './ionic-api.service'
// Env variables
import { environment } from '../../environments/environment'


@Injectable({
  providedIn: 'root'
})
export class HelpersService {

  public baseUrl: string = environment.baseUrl;

  constructor (
    private storage: Storage,
    private router: Router,
    public ionicApi: IonicApiService,
    private httpClient: HttpClient,
  ) { }

  // Sends a get or post request with credentials, returns an observable
  requestWCredientials(type = 'get', url, data = {}): Observable<any> {
    return this.getCredentials().pipe(
      switchMap((userCredentials: any) => {
        if (type === 'get') { // GET Request
          return this.httpClient.get(this.baseUrl + url, {
            headers: {
              Authorization: 'bearer ' + JSON.stringify(userCredentials)
            }
          }).pipe(
            mergeMap((res) => this.checkIfLoggedOut(res)),
            catchError((err) => this.netWorkError(err))
          )
        } else if (type === 'post') { // POST Request
          return this.httpClient.post(this.baseUrl + url, data, {
            headers: {
              Authorization: 'bearer ' + JSON.stringify(userCredentials)
            }
          }).pipe(
            mergeMap((res) => this.checkIfLoggedOut(res)),
            catchError((err) => this.netWorkError(err))
          )
        } else { // Wrong Request
          console.log('Wrong request type')
          return of(null)
        }
      })
    )
  }

  // Sends a get or post request with credentials, returns an observable
  requestNoCredientials(type = 'get', url, data = {}): Observable<any> {
    if (type === 'get') { // GET Request
      return this.httpClient.get(this.baseUrl + url).pipe(
        catchError((err) => this.netWorkError(err))
      )
    } else if (type === 'post') { // POST Request
      return this.httpClient.post(this.baseUrl + url, data).pipe(
        catchError((err) => this.netWorkError(err))
      )
    } else { // Wrong Request
      console.log('Wrong request type')
      return of(null)
    }
  }

  // Error handling
  netWorkError(err): Observable<any> {
    this.ionicApi.presentAlert('error', 'Erreur', 'Une erreur est survenue, veuilez vérifier votre internet.')
    return of(err)
  }

  // Get user credentials
  checkIfLoggedOut(res): Observable<any> {
    if (!res.state && (res.status === 1503 || res.status === 1505)) {
      Promise.all([
        this.storage.set('activeAdmin', null),
      ]).then(() => {
        this.router.navigate(['/login'], { replaceUrl: true })
        return NEVER
      })
    }
    return of(res)
  }

  // Get user credentials
  getCredentials(): Observable<any> {
    return from(this.storage.get('activeAdmin'))
  }
}
