// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
import { UserRole } from '../app/shared/auth.roles';

export const environment = {
  production: false,
  // baseUrl: 'http://127.0.0.1:8000/api/admin/',
  // assetsUrl: 'http://127.0.0.1:8000/',
  // baseUrl: 'https://canadajobetude.com/backend/v0.4/public/api/admin/',
  // assetsUrl: 'https://canadajobetude.com/backend/v0.4/public/',
  baseUrl: 'http://cjebackend.applifier.online/public/api/admin/',
  assetsUrl: 'http://cjebackend.applifier.online/public/',
  
  // vien
  SCARF_ANALYTICS : false,
  adminRoot: '/app',
  defaultMenuType: 'menu-default',
  subHiddenBreakpoint: 1440,
  menuHiddenBreakpoint: 768,
  themeColorStorageKey: 'vien-themecolor',
  isMultiColorActive: true,
  defaultColor: 'light.blueyale',
  isDarkSwitchActive: true,
  defaultDirection: 'ltr',
  themeRadiusStorageKey: 'vien-themeradius',
  isAuthGuardActive: true,
  defaultRole: UserRole.Admin,
};
