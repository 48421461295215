import { Component, OnInit, HostListener } from '@angular/core'
import { Router } from '@angular/router'
import { Subscription } from 'rxjs'
import { LoadingController } from '@ionic/angular'
import { getThemeColor, setThemeColor } from 'src/app/utils/util'
import { SidebarService, ISidebar } from '../sidebar/sidebar.service'
// Services
import { LangService, Language } from 'src/app/shared/lang.service'
import { ApiServiceService } from 'src/app/services/api-service.service'
import { HelpersService } from 'src/app/services/helpers.service'
// Env
import { environment } from 'src/environments/environment'

@Component({
  selector: 'app-topnav',
  templateUrl: './topnav.component.html',
})
export class TopnavComponent implements OnInit {
  adminRoot = environment.adminRoot;
  sidebar: ISidebar
  subscription: Subscription
  languages: Language[]
  currentLanguage: string
  isSingleLang
  isFullScreen = false;
  isDarkModeActive = false;
  searchKey = '';
  AdminId: number = 0;
  adminName: string


  constructor (
    private sidebarService: SidebarService,
    private router: Router,
    private langService: LangService,
    private apiService: ApiServiceService,
    private helpers: HelpersService,
    public loadingController: LoadingController,
  ) {
    this.languages = this.langService.supportedLanguages
    this.currentLanguage = this.langService.languageShorthand
    this.isSingleLang = this.langService.isSingleLang
    this.isDarkModeActive = getThemeColor().indexOf('dark') > -1 ? true : false
  }

  onSignOut() {
    const loading = this.presentLoader('Déconnexion en cours...')
    this.apiService.logOut('activeAdmin')
    loading.then((loader: any) => { loader.dismiss() })
  }

  async presentLoader(msg) {
    const loading = await this.loadingController.create({
      cssClass: 'my-custom-class',
      message: msg,
      spinner: "bubbles",
      duration: 100000
    })
    await loading.present()
    return loading
  }

  getAdminInfos() {
    this.helpers.getCredentials().subscribe(result => {
      if (result !== null) {
        this.AdminId = result.id
        this.adminName = result.user_name
      }
    })
  }

  async ngOnInit(): Promise<void> {
    this.getAdminInfos()
    this.subscription = this.sidebarService.getSidebar().subscribe(
      (res) => { this.sidebar = res },
      (err) => { console.error(`An error occurred: ${err.message}`) }
    )
  }

  showAdminProfile() {
    if (this.AdminId != 0) {
      this.router.navigateByUrl('/app/profile/' + this.AdminId)
    }
  }

  onDarkModeChange(event): void {
    let color = getThemeColor()
    if (color.indexOf('dark') > -1) {
      color = color.replace('dark', 'light')
    } else if (color.indexOf('light') > -1) {
      color = color.replace('light', 'dark')
    }
    setThemeColor(color)
    setTimeout(() => {
      window.location.reload()
    }, 200)
  }

  fullScreenClick(): void {
    if (document.fullscreenElement) {
      document.exitFullscreen()
    } else {
      document.documentElement.requestFullscreen()
    }
  }

  @HostListener('document:fullscreenchange', ['$event'])
  handleFullscreen(event): void {
    if (document.fullscreenElement) {
      this.isFullScreen = true
    } else {
      this.isFullScreen = false
    }
  }

  onLanguageChange(lang): void {
    this.langService.language = lang.code
    this.currentLanguage = this.langService.languageShorthand
  }


  menuButtonClick = (
    e: { stopPropagation: () => void },
    menuClickCount: number,
    containerClassnames: string
  ) => {
    if (e) {
      e.stopPropagation()
    }

    setTimeout(() => {
      const event = document.createEvent('HTMLEvents')
      event.initEvent('resize', false, false)
      window.dispatchEvent(event)
    }, 350)

    this.sidebarService.setContainerClassnames(
      ++menuClickCount,
      containerClassnames,
      this.sidebar.selectedMenuHasSubItems
    )
  }

  mobileMenuButtonClick = (
    event: { stopPropagation: () => void },
    containerClassnames: string
  ) => {
    if (event) {
      event.stopPropagation()
    }
    this.sidebarService.clickOnMobileMenu(containerClassnames)
  }

  searchKeyUp(event: KeyboardEvent): void {
    if (event.key === 'Enter') {
      this.search()
    } else if (event.key === 'Escape') {
      const input = document.querySelector('.mobile-view')
      if (input && input.classList) {
        input.classList.remove('mobile-view')
      }
      this.searchKey = ''
    }
  }

  searchAreaClick(event): void {
    event.stopPropagation()
  }
  searchClick(event): void {
    if (window.innerWidth < environment.menuHiddenBreakpoint) {
      let elem = event.target
      if (!event.target.classList.contains('search')) {
        if (event.target.parentElement.classList.contains('search')) {
          elem = event.target.parentElement
        } else if (
          event.target.parentElement.parentElement.classList.contains('search')
        ) {
          elem = event.target.parentElement.parentElement
        }
      }

      if (elem.classList.contains('mobile-view')) {
        this.search()
        elem.classList.remove('mobile-view')
      } else {
        elem.classList.add('mobile-view')
      }
    } else {
      this.search()
    }
    event.stopPropagation()
  }

  search(): void {
    if (this.searchKey && this.searchKey.length > 1) {
      this.router.navigate([this.adminRoot + '/#'], {
        queryParams: { key: this.searchKey.toLowerCase().trim() },
      })
      this.searchKey = ''
    }
  }

  @HostListener('document:click', ['$event'])
  handleDocumentClick(event): void {
    const input = document.querySelector('.mobile-view')
    if (input && input.classList) {
      input.classList.remove('mobile-view')
    }
    this.searchKey = ''
  }
}
