<div class="split left">
  <div class="centered">
    <!-- image Background -->
  </div>
</div>

<div class="split right">
  <div class="centered">
    <img src="../../../assets/logos/black.svg" alt="cje-logo">
    <div class="number">404</div>
    <div class="text">Page Introuvable</div>
    <a routerLink="/login" class="btn btn-primary btn-lg btn-shadow" type="submit">
      Retour
    </a>
  </div>
</div>
