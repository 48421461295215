import { Component, ViewChild, OnInit, TemplateRef } from '@angular/core'
import { LoadingController } from '@ionic/angular'
import { ColumnMode } from '@swimlane/ngx-datatable'
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal'
// Services
import { AdminService } from 'src/app/services/admin.service'
import { HelpersService } from 'src/app/services/helpers.service'

@Component({
  selector: 'app-admins',
  templateUrl: './admins.component.html',
  styleUrls: ['./admins.component.scss']
})
export class AdminsComponent implements OnInit {
  @ViewChild('myTable') table: any

  expanded: any = {};
  timeout: any
  rows: any = [];
  temp = [];
  modalRef: BsModalRef

  itemsPerPage = 10;
  ColumnMode = ColumnMode;

  columns = [
    { prop: 'action', name: 'Action' },
    { prop: 'email', name: 'Email' },
    { prop: 'tel', name: 'Tel' },
    { prop: 'nom', name: 'Nom' },
    { prop: 'prenom', name: 'Prenom' },
    { prop: 'statut', name: 'Statut' }
  ];

  statut: number

  constructor (
    private adminService: AdminService,
    private loadingController: LoadingController,
    private modalService: BsModalService,
    private helpers: HelpersService,
  ) { }

  ngOnInit(): void {
    this.getAdmins()
  }

  onPage(event): void {
  }

  toggleExpandRow(row): void {
    this.table.rowDetail.toggleExpandRow(row)
  }

  onDetailToggle(event): void {
  }

  updateFilter(event): void {
    const val = event.target.value.toLowerCase().trim()
    const count = this.columns.length
    const keys = Object.keys(this.temp[0])
    const temp = this.temp.filter(item => {
      for (let i = 0; i < count; i++) {
        if ((item[keys[i]] && item[keys[i]].toString().toLowerCase().indexOf(val) !== -1) || !val) {
          return true
        }
      }
    })
    this.rows = temp
    this.table.offset = 0
  }

  getAdmins() {
    this.helpers.getCredentials().subscribe(user => {
      const loading = this.presentLoader('Récuperation des données en cours...')
      this.adminService.getAdmins().subscribe(response => {
        let admins = response.data.admins
        // remove connected admin from list
        let indexOfAdmin = this.findWithAttr(admins, 'id', user.id)
        admins.splice(indexOfAdmin, 1)
        this.rows = admins.slice(0, 20).map(({ id, code, email, tel, first_name, last_name, statut }) =>
          ({ action: id, code, email, tel, prenom: first_name, nom: last_name, statut }))
        this.temp = [...this.rows]
        loading.then((loader: any) => { loader.dismiss() })
      })
    })
  }

  findWithAttr(array, attr, value) {
    for (var i = 0; i < array.length; i += 1) {
      if (array[i][attr] === value) {
        return i
      }
    }
    return -1
  }

  deleteAdmin(id: number) {
    const loading = this.presentLoader('Suppression en cours...')
    this.adminService.deleteAdmin(id).subscribe(response => {
      this.getAdmins()
      this.decline()
      loading.then((loader: any) => { loader.dismiss() })
    })
  }

  getAdminAccountStatut(id: number): number {
    let statut: number
    this.rows.forEach(row => { if (row['action'] === id) { statut = row['statut'] } })
    return statut
  }

  toggleAdminAccountStatut(id: number) {
    const loading = this.presentLoader('Activation/Désactivation en cours...')
    this.adminService.toggleAdminAccountStatut(id).subscribe(response => {
      this.getAdmins()
      loading.then((loader: any) => { loader.dismiss() })
    })
  }

  async presentLoader(msg) {
    const loading = await this.loadingController.create({
      cssClass: 'my-custom-class',
      message: msg,
      spinner: "bubbles",
      duration: 100000
    })
    await loading.present()
    return loading
  }

  openModal(template: TemplateRef<any>): void {
    this.modalRef = this.modalService.show(template, { class: 'modal-sm' })
  }

  decline(): void {
    this.modalRef.hide()
  }

}
