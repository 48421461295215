import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
// Services
import { HelpersService } from './helpers.service'

@Injectable({
  providedIn: 'root'
})
export class AdminService {

  constructor (
    private helpers: HelpersService,
  ) { }

  getAdminsLvls(): Observable<any> {
    return this.helpers.requestWCredientials('get', 'getAdminsLvls')
  }

  getAdmins(): Observable<any> {
    return this.helpers.requestWCredientials('get', 'getAdmins')
  }

  getAdminById(id): Observable<any> {
    return this.helpers.requestWCredientials('get', 'getAdmin/' + id)
  }

  addAdmin(data): Observable<any> {
    return this.helpers.requestWCredientials('post', 'addAdmin', data)
  }

  deleteAdmin(id): Observable<any> {
    return this.helpers.requestWCredientials('get', 'deleteAdmin/' + id)
  }

  updateAdmin(data): Observable<any> {
    return this.helpers.requestWCredientials('post', 'updateAdmin/' + data.id, data)
  }

  toggleAdminAccountStatut(id): Observable<any> {
    return this.helpers.requestWCredientials('get', 'toggleAdminAccountStatut/' + id)
  }
}
